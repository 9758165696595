<template>
  <div class="about section-vh">
    <b-container>
      <b-row>
        <b-col class="col my-5">
          <div class="text-left text-white" v-if="$i18n.locale === 'ru'">
            <p>
              <b class="text-left cs-text-color"
                >Продолжительность турнира составляет не более одного дня.</b
              >
            </p>
            <p>
              Турнир состоит из 24 встреч: 24 встречи группового этапа, круговая система (round-robin) в два круга. Игра ведется без дополнительного времени. Количество участников турнира – 3 команды по 3 человека в каждой.
            </p>
            <p>
              <b class="text-left cs-text-color"
                >Турнир проводится в один этап:</b
              ><br />
            </p>
            <p>
              Групповой этап. Встречи группового этапа проводятся только в основное время без экстра-таймов. В матче может быть зафиксирована ничья.
            </p>
            <p>
              Все команды играют в два круга между собой (у каждого из участников по 12 встреч), всего 24 встреч в группе. Победитель выявляется на основании лучших показателей – набранных очков.
            </p>
            <p>
              В ходе проведения группового этапа турнира команды набирают игровые очки, которые по итогам каждой встречи турнира складываются. Итоговое место команды в групповом этапе определяется по сумме набранных очков.
            </p>
            <p>
              - За победу во встрече команде начисляются 3 очка<br />
              - За ничью – 1 очко<br />
              - Проигравшей команде очки не начисляются
            </p>
            <p>
              <b class="text-left cs-text-color"
                >В случае если одинаковое количество очков было набрано двумя
                командами, победитель определяется согласно правилам, указанным
                ниже (по ранжированию):</b
              >
            </p>
            <p>
              - по очкам, набранным во встречах между собой; <br />
              - по разнице уничтожений и смертей в матчах между собой; <br />
              - по разнице уничтожений и смертей во всех матчах; <br />
              - по большему количеству уничтожений во всех матчах; <br />
              - по жребию.
            </p>
            <p>
              <b class="text-left cs-text-color"
                >В случае если одинаковое количество очков было набрано тремя и
                более командами, победитель определяется согласно правилам,
                указанным ниже (по ранжированию):</b
              >
            </p>
            <p>
              - по наибольшему количеству побед; <br />
              - по очкам, набранным во встречах между собой; <br />
              - по разнице уничтожений и смертей в матчах между собой; <br />
              - по большему количеству уничтожений в матчах между собой; <br />
              - по жребию.
            </p>
            <p>
              По окончании группового этапа производится подсчет очков, по
              итогам которого выявляется победитель турнира.
            </p>
          </div>

          <div class="text-left text-white" v-else>
            <p>
              <b class="text-left cs-text-color">The tournament lasts no more than one day.</b>
            </p>
            <p>
              The tournament consists of 24 matches: 24 group stage matches, a two-group round-robin. The game is played without extra time. Number of tournament participants: 3 teams of 3.
            </p>
            <p>
              <b class="text-left cs-text-color">The tournament is held in one stage:</b><br />
            </p>
            <p>
              Group stage. Group stage matches are held only with regular time, without extra time. Matches may end in a draw.
            </p>
            <p>
              All teams play each other in two groups (each participant has 12 matches), with a total of 24 matches in a group. The winner is determined on the basis of the number of points scored.
            </p>
            <p>
              During the group stage of the tournament, the teams earn game points that are added up at the end of each tournament match. The final place of the team in the group stage is determined by the sum of points earned.
            </p>
            <p>A team is awarded 3 points for winning a match and 1 point for a draw, and the losing team is not awarded any points.
            </p>
            <p>
              <b class="text-left cs-text-color">In the event that the same number of points was earned by two teams, the winner is determined based on the rules indicated below (by ranking):</b>
            </p>
            <p>
              - by points earned in head-to-head matches;<br />
              - by the difference in destructions and deaths in head-to-head matches;<br />
              - by the difference in destructions and deaths in all matches;<br />
              - by the most destructions in all matches;<br />
              - by coin toss.
            </p>
            <p>
              <b class="text-left cs-text-color">In the event that the same number of points has been earned by three or more teams, the winner is determined based on the rules indicated below (by ranking):</b>
            </p>
            <p>
              - by the most wins;<br />
              - by points earned in head-to-head matches;<br />
              - by the difference in destructions and deaths in head-to-head matches;<br />
              - by the most destructions in head-to-head matches;<br />
              - by coin toss.
            </p>
            <p>
              At the end of the group stage, the points are calculated and the winner of the tournament is announced.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "mir-tankov_rules",
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: this.$t("meta.sports.mir-tankov.rules.title"),
      titleTemplate: "H2H Liga | %s",
      meta: [
        {
          name: "description",
          content: this.$t("meta.sports.mir-tankov.rules.description")
        }
      ]
    };
  }
};
</script>

<style scoped>
.border_title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
